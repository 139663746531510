//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, } from 'vuex';
import navItems from '@/data/navigationItems.json';
import { navClick, } from '@/services/gtmManager';

export default {
  name: 'NavBar',
  data () {
    return {
      showMenu: false,
      navItems,
      loading: false,
      lastScrollY: 0,
      showNavbar: true, // initially shown
    };
  },
  computed: {
    ...mapGetters('search', ['state', 'profession']),
    isCoursesPage () {
      return this.$route.path.includes('courses');
    },
  },
  watch: {
    $route (to, from) {
      if (to.name === 'account') {
        this.loading = false;
      }
    },
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions('search', [
      'setState', 'setProfession', 'applyFilters',
    ]),
    toggleNavbar () {
      this.showMenu = !this.showMenu;
    },
    goToAccount () {
      if (this.$route.name !== 'account') {
        this.loading = true;
        this.$router.push('/account');
      }
    },
    sendEvent (eventName, linkClick, linkId, linkClass, linkUrl) {
      navClick(this.$gtm, eventName, linkClick, linkId, linkClass, linkUrl);
    },
    handleScroll () {
      const currentScrollY = window.scrollY;

      if (currentScrollY < this.lastScrollY) {
        this.showNavbar = true; // scrolling up
      } else if (currentScrollY > 100) {
        this.showNavbar = false; // scrolling down
      }

      this.lastScrollY = currentScrollY;
    },
  },
};
