export default {

  exists () {
    return typeof window !== 'undefined' && typeof localStorage !== 'undefined';
  },

  get (key, defaultValue) {
    if (!this.exists()) {
      return defaultValue;
    }
    try {
      const value = localStorage.getItem(key);
      return (value && typeof value === 'string' ? JSON.parse(value) : defaultValue) || defaultValue;
    } catch (e) {
      console.error('Error reading from localStorage:', e);
      return defaultValue;
    }
  },

  set (key, value) {
    if (!this.exists()) {
      return;
    }
    try {
      localStorage.setItem(key, JSON.stringify(value));
      console.log('setItem:', localStorage.getItem(key));
    } catch (e) {
      console.error('Error writing to localStorage:', e);
    }
  },

  remove (key) {
    if (!this.exists()) {
      return;
    }
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error('Error removing from localStorage:', e);
    }
  },

  check (key) {
    return this.get(key, undefined);
  },

  clear () {
    if (!this.exists()) {
      return;
    }
    try {
      localStorage.clear();
    } catch (e) {
      console.error('Error clearing localStorage:', e);
    }
  },
};
