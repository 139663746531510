export const state = () => ({
  groupSaleCourseMin: 209.99,
  groupSaleCourseMax: 259.99,
  courseMin: 49.99,
  courseMax: 269.99,
  core100: 159.99,
  core100P: 209.99,
  onlineAccess: 269.99,
  virtualAccess: 299.99,
  oaWebinar: 270.99,
  allAccess: 319.99,
  subhep_monthly: 9.99,
  symposiumNonSub: 699,
  symposiumSub: 499,
});
