import Vue from 'vue';
import VueTailwind from 'vue-tailwind';
import {
  TAlert,
  TButton,
  TCard,
  TDatepicker,
  TDialog,
  TDropdown,
  TInput,
  TInputGroup,
  TModal,
  TPagination,
  TRadio,
  TRichSelect,
  TSelect,
  TTable,
  TTag,
  TTextarea,
  TToggle,
} from 'vue-tailwind/dist/components';

const settings = {
  't-input': {
    component: TInput,
    props: {
      fixedClasses: 'block w-full px-3 py-2 transition duration-100 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'summit-black text-sm placeholder-gray-400 bg-white border-gray-300 focus:border-primary-140 ',
      variants: {
        disabled: 'text-sm border-gray-300 bg-gray-100 placeholder-gray-200 text-gray-900',
        nav: 'placeholder-black border-[1.5px] bg-white mb-3 h-10 w-5 md:w-80 px-5 pr-16 rounded font-bold text-black focus:outline-none',
        footer: 'placeholder-black border-[1.5px] bg-white mb-3 h-10 w-5 md:w-80 mx-auto text-2xl px-5 pr-16 rounded font-bold text-black focus:outline-none',
        danger: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
        success: 'border-green-300 bg-green-50 placeholder-gray-400 text-green-900',
        ceTracker: 'placeholder-black appearance-none block w-full h-12 text-black font-bold border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
      },
    },
  },
  't-textarea': {
    component: TTextarea,
    props: {
      fixedClasses: 'block w-full px-3 py-2 transition duration-100 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-black placeholder-gray-400 bg-white border-gray-300 focus:border-primary-140 ',
      variants: {
        danger: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
        success: 'border-green-300 bg-green-50 placeholder-gray-400 text-green-900',
      },
    },
  },
  't-select': {
    component: TSelect,
    props: {
      fixedClasses: 'block appearance-none w-full px-4 pr-8 rounded focus:outline-none focus:bg-white focus:border-gray-500',
      classes: 'block text-black pl-3 border rounded shadow-sm bg-white border-gray-300 focus:border-primary-140 ',
      variants: {
        clientForm: 'block pl-3 pr-8 py-2 text-gray-400 border text-sm rounded border-gray-200 bg-white focus:border-primary-140',
        signup: 'block pl-3 pr-8 py-4 text-black border rounded border-gray-200 font-bold h4 bg-white focus:border-primary-140',
        checkout: 'border rounded shadow-sm border-gray-300 block pl-3 pr-8 py-2 text-sm text-black bg-white focus:border-primary-140',
        courseEnrollment: 'border rounded shadow-sm border-gray-300 block pl-2 pr-8 py-1 text-sm font-bold text-black bg-white focus:border-primary-140',
        home: 'block pl-3 pr-8 py-4 text-primary-140 border-none font-bold h4 bg-white focus:border-primary-140 ',
        course: 'block pl-3 pr-8 py-3 text-primary-140 border-2 border-matte-gray font-bold h4 bg-white focus:border-primary-140 ',
        courseMobile: 'block pl-3 pr-8 py-2 text-primary-140 border-2 border-matte-gray font-bold text-sm font-bold bg-white focus:border-primary-140 ',
        danger: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
        success: 'border-green-300 bg-green-50 placeholder-gray-400 text-green-900',
        nav: 'p-0 text-black',
        core100: 'text-pink border rounded border-pink py-2 font-bold text-sm',
        ceTracker: 'block pl-3 pr-8 h-12 text-black border rounded border-gray-200 font-bold h4 bg-white focus:border-primary-140',
      },
    },
  },
  't-radio': {
    component: TRadio,
    props: {
      fixedClasses: 'transition duration-100 ease-in-out shadow-sm focus:border-primary-140 focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-primary-140 border-gray-300',
      variants: {
        error: 'text-red-500 border-red-300',
        success: 'text-green-500 border-green-300',
      },
    },
  },
  't-button': {
    component: TButton,
    props: {
      fixedClasses: 'font-bold px-4 inline-block transition duration-100 ease-in-out focus:ring-2 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-white rounded px-4 py-2 bg-cta-primary hover:bg-cta-secondary focus:border-cta-secondary focus:ring-cta-secondary',
      variants: {
        ctaPrimary: 'py-2 rounded text-white bg-cta-primary hover:bg-opacity-90 focus:border-green-200 focus:ring-green-200',
        ctaSecondary: 'bg-white py-2 border border-4 rounded border-cta-primary text-sm text-cta-primary font-bold hover:bg-cta-primary hover:text-white',
        ctaPrimaryOutlined: 'bg-white py-2 border border-4 rounded border-cta-primary w-full text-sm text-cta-primary font-bold hover:bg-cta-primary hover:text-white',
        ctaSecondaryOutlined: 'bg-white py-2 border border-4 rounded border-primary-140 text-primary-140 hover:bg-primary-140 hover:text-white',
        success: 'py-2 text-white bg-green-500 hover:bg-green-600 focus:border-green-600 focus:ring-green-600',
        error: 'py-2 text-white bg-red-500 rounded hover:bg-red-600 focus:border-red-600 focus:ring-red-600',
        errorOutlined: 'py-2 text-red-500 bg-white border border-4 rounded border-red-600',
        ceTracker: 'placeholder-black appearance-none block w-full h-12 text-black font-bold border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
        accountOrderCard: 'py-2 rounded-none w-full rounded-b py-3 bg-cta-secondary text-white font-bold',
        accountReportCard: 'py-2 rounded-none w-full py-3 bg-orange text-white font-bold',
        accountOrderReport: 'py-2 rounded py-3 bg-orange text-white font-bold',
        symposiumCta: 'bg-symposium-accent rounded-full py-1 px-2 text-black font-bold hover:bg-symposium-accent-lighter',
      },
    },
  },
  't-input-group': {
    component: TInputGroup,
    props: {
      fixedClasses: {
        wrapper: '',
        label: 'block',
        body: '',
        feedback: ' text-sm text-sm',
        description: 'text-gray-400 text-sm',
      },
      classes: {
        wrapper: '',
        label: '',
        body: '',
        feedback: 'text-gray-400',
        description: 'text-gray-400',
      },
      variants: {
        danger: {
          label: 'text-red-500',
          feedback: 'text-red-500',
        },
        success: {
          label: 'text-green-500',
          feedback: 'text-green-500',
        },
      },
    },
  },
  't-rich-select': {
    component: TRichSelect,
    props: {
      fixedClasses: {
        wrapper: 'relative',
        buttonWrapper: 'inline-block relative w-full',
        selectButton: 'w-full flex text-left justify-between items-center px-3 py-2 text-black transition duration-100 ease-in-out border rounded shadow-sm focus:border-primary-140 focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
        selectButtonLabel: 'block truncate',
        selectButtonPlaceholder: 'block truncate',
        selectButtonIcon: 'fill-current flex-shrink-0 ml-1 h-4 w-4',
        selectButtonClearButton: 'rounded flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6 transition duration-100 ease-in-out',
        selectButtonClearIcon: 'fill-current h-3 w-3',
        dropdown: 'absolute w-full z-20 -mt-1 absolute border-b border-l border-r rounded-b shadow-sm z-10',
        dropdownFeedback: '',
        loadingMoreResults: '',
        optionsList: 'overflow-auto',
        searchWrapper: 'inline-block w-full',
        searchBox: 'inline-block w-full',
        optgroup: '',
        option: 'cursor-pointer',
        disabledOption: 'opacity-50 cursor-not-allowed',
        highlightedOption: 'cursor-pointer',
        selectedOption: 'cursor-pointer',
        selectedHighlightedOption: 'cursor-pointer',
        optionContent: '',
        optionLabel: 'truncate block',
        selectedIcon: 'fill-current h-4 w-4',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: '',
      },
      classes: {
        wrapper: '',
        buttonWrapper: '',
        selectButton: 'bg-white border-gray-300',
        selectButtonLabel: '',
        selectButtonPlaceholder: 'text-gray-400',
        selectButtonIcon: 'text-gray-600',
        selectButtonClearButton: 'hover:bg-background-primary text-gray-600',
        selectButtonClearIcon: '',
        dropdown: 'bg-white border-gray-300',
        dropdownFeedback: 'pb-2 px-3 text-gray-400 text-sm',
        loadingMoreResults: 'pb-2 px-3 text-gray-400 text-sm',
        optionsList: '',
        searchWrapper: 'p-2 placeholder-gray-400',
        searchBox: 'px-3 py-2 bg-gray-50 text-sm rounded border focus:outline-none focus:shadow-outline border-gray-300',
        optgroup: 'text-gray-400 uppercase text-xs py-1 px-2 font-semibold',
        option: '',
        disabledOption: '',
        highlightedOption: 'bg-background-primary',
        selectedOption: 'font-semibold bg-gray-100 bg-primary-140 font-semibold text-white',
        selectedHighlightedOption: 'font-semibold bg-gray-100 bg-primary-140-600 font-semibold text-white',
        optionContent: 'flex justify-between items-center px-3 py-2',
        optionLabel: '',
        selectedIcon: '',
        enterClass: 'opacity-0',
        enterActiveClass: 'transition ease-out duration-100',
        enterToClass: 'opacity-100',
        leaveClass: 'opacity-100',
        leaveActiveClass: 'transition ease-in duration-75',
        leaveToClass: 'opacity-0',
      },
      variants: {
        danger: {
          selectButton: 'border-red-300 bg-red-50 text-red-900',
          selectButtonPlaceholder: 'text-red-200',
          selectButtonIcon: 'text-red-500',
          selectButtonClearButton: 'hover:bg-red-200 text-red-500',
          dropdown: 'bg-red-50 border-red-300',
        },
        success: {
          selectButton: 'border-green-300 bg-green-50 text-green-900',
          selectButtonIcon: 'text-green-500',
          selectButtonClearButton: 'hover:bg-green-200 text-green-500',
          dropdown: 'bg-green-50 border-green-300',
        },
      },
    },
  },
  't-datepicker': {
    component: TDatepicker,
    props: {
      fixedClasses: {
        navigator: 'flex',
        navigatorViewButton: 'flex items-center',
        navigatorViewButtonIcon: 'flex-shrink-0 h-5 w-5',
        navigatorViewButtonBackIcon: 'flex-shrink-0 h-5 w-5',
        navigatorLabel: 'flex items-center py-1',
        navigatorPrevButtonIcon: 'h-6 w-6 inline-flex',
        navigatorNextButtonIcon: 'h-6 w-6 inline-flex',
        inputWrapper: 'relative',
        viewGroup: 'inline-flex flex-wrap',
        view: 'w-64',
        calendarDaysWrapper: 'grid grid-cols-7',
        calendarHeaderWrapper: 'grid grid-cols-7',
        monthWrapper: 'grid grid-cols-4',
        yearWrapper: 'grid grid-cols-4',
        input: 'block w-full px-3 py-2 transition duration-100 ease-in-out border rounded shadow-sm focus:border-primary-140 focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
        clearButton: 'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6',
        clearButtonIcon: 'fill-current h-3 w-3',
      },
      classes: {
        wrapper: 'flex flex-col',
        dropdownWrapper: 'relative z-10',
        dropdown: 'origin-top-left absolute rounded shadow bg-white overflow-hidden mt-1',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95',
        inlineWrapper: '',
        inlineViews: 'rounded bg-white border mt-1 inline-flex',
        inputWrapper: '',
        input: 'text-black placeholder-gray-400 border-gray-200',
        clearButton: 'hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600',
        clearButtonIcon: '',
        viewGroup: '',
        view: '',
        navigator: 'pt-2 px-3',
        navigatorViewButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100',
        navigatorViewButtonIcon: 'fill-current text-gray-400',
        navigatorViewButtonBackIcon: 'fill-current text-gray-400',
        navigatorViewButtonMonth: 'text-gray-700 font-semibold',
        navigatorViewButtonYear: 'text-gray-500 ml-1',
        navigatorViewButtonYearRange: 'text-gray-500 ml-1',
        navigatorLabel: 'py-1',
        navigatorLabelMonth: 'text-gray-700 font-semibold',
        navigatorLabelYear: 'text-gray-500 ml-1',
        navigatorPrevButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorNextButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorPrevButtonIcon: 'text-gray-400',
        navigatorNextButtonIcon: 'text-gray-400',
        calendarWrapper: 'px-3 pt-2',
        calendarHeaderWrapper: '',
        calendarHeaderWeekDay: 'uppercase text-xs text-gray-500 w-8 h-8 flex items-center justify-center',
        calendarDaysWrapper: '',
        calendarDaysDayWrapper: 'w-full h-8 flex flex-shrink-0 items-center',
        otherMonthDay: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-background-primary text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed',
        emptyDay: '',
        inRangeFirstDay: 'text-sm bg-primary-140 text-white w-full h-8 rounded-l-full',
        inRangeLastDay: 'text-sm bg-primary-140 text-white w-full h-8 rounded-r-full',
        inRangeDay: 'text-sm bg-primary-120 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed',
        selectedDay: 'text-sm rounded-full w-8 h-8 mx-auto bg-primary-140 text-white disabled:opacity-50 disabled:cursor-not-allowed',
        activeDay: 'text-sm rounded-full bg-background-primary w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        highlightedDay: 'text-sm rounded-full bg-primary-120 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        day: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-background-primary disabled:opacity-50 disabled:cursor-not-allowed',
        today: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-background-primary disabled:opacity-50 disabled:cursor-not-allowed border border-primary-140',
        monthWrapper: 'px-3 pt-2',
        selectedMonth: 'text-sm rounded w-full h-12 mx-auto bg-primary-140 text-white',
        activeMonth: 'text-sm rounded w-full h-12 mx-auto bg-background-primary',
        month: 'text-sm rounded w-full h-12 mx-auto hover:bg-background-primary',
        yearWrapper: 'px-3 pt-2',
        year: 'text-sm rounded w-full h-12 mx-auto hover:bg-background-primary',
        selectedYear: 'text-sm rounded w-full h-12 mx-auto bg-primary-140 text-white',
        activeYear: 'text-sm rounded w-full h-12 mx-auto bg-background-primary',
      },
      variants: {
        danger: {
          input: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
          clearButton: 'hover:bg-red-200 text-red-500',
        },
      },
    },
  },
  't-tag': {
    component: TTag,
    props: {
      fixedClasses: '',
      classes: '',
      variants: {
        title: 'text-2xl leading-8 font-extrabold text-gray-900 tracking-tight',
        subtitle: 'text-lg leading-6 font-medium text-gray-900',
        error: 'text-red-500',
        badge: 'inline-flex items-center px-3 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800',
        avatar: 'inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500 overflow-hidden leading-none text-center',
      },
    },
  },
  't-toggle': {
    component: TToggle,
    props: {
      fixedClasses: {
        wrapper: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200',
        wrapperChecked: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200  border-2 border-transparent focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50',
        wrapperDisabled: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed',
        wrapperCheckedDisabled: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed border-2 border-transparent focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50',
        button: 'inline-block absolute transform translate-x-0 transition ease-in-out duration-200',
        buttonChecked: 'inline-block absolute transform translate-x-full transition ease-in-out duration-200',
        checkedPlaceholder: 'inline-block',
        uncheckedPlaceholder: 'inline-block',
      },
      classes: {
        wrapper: 'bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50',
        wrapperChecked: 'bg-primary-140 rounded-full',
        wrapperDisabled: 'bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50',
        wrapperCheckedDisabled: 'bg-primary-140',
        button: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs',
        buttonChecked: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-primary-140 text-xs',
        checkedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs',
        uncheckedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs',
      },
      variants: {
        danger: {
          wrapperChecked: 'bg-red-500 rounded-full',
          wrapperCheckedDisabled: 'bg-red-500 rounded-full',
        },
        success: {
          wrapperChecked: 'bg-green-500 rounded-full',
          wrapperCheckedDisabled: 'bg-green-500 rounded-full',
        },
        box: {
          wrapper: 'bg-gray-100 rounded-sm border-2 border-transparent focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50',
          wrapperChecked: 'bg-primary-140 rounded-sm',
          wrapperCheckedDisabled: 'bg-primary-140 rounded-sm',
          button: 'h-6 w-6 rounded-sm bg-white shadow flex items-center justify-center text-gray-400 text-xs',
          buttonChecked: 'h-6 w-6 rounded-sm  bg-white shadow flex items-center justify-center text-primary-140 text-xs',
          checkedPlaceholder: 'rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs',
          uncheckedPlaceholder: 'rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs',
        },
      },
    },
  },
  't-alert': {
    component: TAlert,
    props: {
      fixedClasses: {
        wrapper: 'relative flex items-center p-4 border-l-4  rounded shadow-sm',
        body: 'flex-grow',
        close: 'absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded  focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50',
        closeIcon: 'fill-current h-4 w-4',
      },
      classes: {
        wrapper: 'bg-primary-140-50 border-primary-140',
        body: 'text-primary-140',
        close: 'text-primary-140 hover:bg-primary-120',
      },
      variants: {
        danger: {
          wrapper: 'bg-red-50 border-red-500',
          body: 'text-red-700',
          close: 'text-red-500 hover:bg-red-200',
        },
        success: {
          wrapper: 'bg-green-50 border-green-500',
          body: 'text-green-700',
          close: 'text-green-500 hover:bg-green-200',
        },
      },
    },
  },
  't-card': {
    component: TCard,
    props: {
      fixedClasses: {
        wrapper: 'w-70 rounded shadow-sm hover:shadow-md',
        body: '',
        header: 'border-b rounded-t',
        footer: 'border-t rounded-b',
      },
      classes: {
        wrapper: 'bg-white',
        body: '',
        header: 'border-gray-100',
        footer: 'border-gray-100',
      },
      variants: {
        courseCards: {
          wrapper: 'border bg-white border-gray-100',
          body: '',
          header: 'border-gray-100',
          footer: 'border-gray-100',
        },
        danger: {
          wrapper: 'bg-red-50 text-red-700 border-red-200',
          header: 'border-red-200 text-red-700',
          footer: 'border-red-200 text-red-700',
        },
      },
    },
  },
  't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay: 'z-50 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50',
        wrapper: 'relative mx-auto z-50 px-3 py-12',
        modal: 'overflow-visible relative  rounded',
        body: 'p-3',
        header: 'border-b p-3 rounded-t',
        footer: ' p-3 rounded-b',
        close: 'flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50',
      },
      classes: {
        overlay: 'bg-black',
        wrapper: 'max-w-xl',
        modal: 'bg-white shadow',
        body: 'p-3',
        header: 'border-gray-100',
        footer: 'bg-gray-100',
        close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
        closeIcon: 'fill-current h-4 w-4',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: '',
      },
      variants: {
        danger: {
          overlay: 'bg-red-100',
          header: 'border-red-50 text-red-700',
          close: 'bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border',
          modal: 'bg-white border border-red-100 shadow-lg',
          footer: 'bg-red-50',
        },
        search: {
          modal: 'bg-transparent border-none shadow-none',
        },
        virtualConference: {
          wrapper: 'md:max-w-3xl',
        },
        freeTrial: {
          wrapper: 'md:max-w-5xl',
        },
        hepLandingPage: {
          wrapper: 'max-w-6xl',
        },
      },
    },
  },
  't-dialog': {
    component: TDialog,
    props: {
      fixedClasses: {
        overlay: 'overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed z-40 bg-opacity-50',
        wrapper: 'relative mx-auto',
        modal: 'overflow-visible relative ',
        close: 'flex items-center justify-center  rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50',
        dialog: 'overflow-visible relative',
        iconWrapper: 'flex flex-shrink-0 h-12 items-center justify-center rounded-full w-12 mx-auto',
        icon: 'w-6 h-6',
        okButton: 'block px-4 py-2 text-white transition duration-100 ease-in-out border border-transparent rounded shadow-sm focus:border-primary-140 focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-xs',
        buttons: 'p-3 flex space-x-4 justify-center bg-gray-100 rounded-b',
      },
      classes: {
        close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
        closeIcon: 'fill-current h-4 w-4',
        overlay: 'bg-black',
        wrapper: 'z-50 max-w-lg px-3 py-12',
        dialog: 'bg-white shadow rounded text-left',
        body: 'p-3 space-y-3',
        buttons: 'bg-gray-100',
        iconWrapper: 'bg-gray-100',
        icon: 'text-gray-500',
        content: 'w-full flex justify-center flex-col',
        titleWrapper: '',
        title: 'text-lg font-semibold text-center',
        textWrapper: 'text-left w-full',
        text: '',
        cancelButton: 'block px-4 py-2 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-100 focus:border-gray-100 focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-xs',
        okButton: 'bg-primary-140 hover:bg-primary-140-600',
        inputWrapper: 'mt-3 flex items-center space-x-3',
        input: 'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-primary-140 focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full',
        select: 'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-primary-140 focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed w-full',
        radioWrapper: 'flex items-center space-x-2',
        radio: 'text-primary-140 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-primary-140 focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
        radioText: '',
        checkboxWrapper: 'flex items-center space-x-2',
        checkbox: 'text-primary-140 transition duration-100 ease-in-out border-gray-300 rounded shadow-sm focus:border-primary-140 focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
        checkboxText: '',
        errorMessage: 'text-red-500 block text-sm',
        busyWrapper: 'absolute bg-opacity-50 bg-white flex h-full items-center justify-center left-0 top-0 w-full',
        busyIcon: 'animate-spin h-6 w-6 fill-current text-gray-500',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: '',
      },
      variants: {
        danger: {
          overlay: 'bg-red-100',
          okButton: 'bg-red-500 hover:bg-red-600',
          iconWrapper: 'bg-red-50',
          buttons: 'bg-red-50',
          icon: 'text-red-500',
        },
        horizontal: {
          body: 'p-3 flex space-x-3',
          title: 'text-lg font-semibold',
        },
      },
    },
  },
  't-table': {
    component: TTable,
    props: {
      classes: {
        table: 'min-w-full divide-y divide-gray-100 shadow-sm border-gray-200 border',
        thead: '',
        theadTr: '',
        theadTh: 'px-3 py-2 font-semibold text-left bg-gray-100 border-b',
        tbody: 'bg-white divide-y divide-gray-100',
        tr: '',
        td: 'px-3 py-2 whitespace-no-wrap',
        tfoot: '',
        tfootTr: '',
        tfootTd: '',
      },
      variants: {
        thin: {
          td: 'p-1 whitespace-no-wrap text-sm',
          theadTh: 'p-1 font-semibold text-left bg-gray-100 border-b text-sm',
        },
      },
    },
  },
  't-pagination': {
    component: TPagination,
    props: {
      classes: {
        wrapper: 'table border-collapse text-center bg-white mx-auto shadow-sm',
        element: 'w-4 h-4 border text-black border-gray-200 table-cell hover:border-primary-40',
        activeElement: 'w-4 h-4 border border-gray-200 border-primary-140 table-cell hover:border-primary-140-600',
        disabledElement: 'w-4 h-4 border border-gray-200 table-cell',
        ellipsisElement: 'w-4 h-4 border border-gray-200 hidden md:table-cell',
        activeButton: 'bg-primary-140 w-4 h-4 text-black hover:bg-primary-140 transition duration-100 ease-in-out focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50',
        disabledButton: 'opacity-25 w-4 h-4 cursor-not-allowed transition duration-100 ease-in-out',
        button: 'hover:bg-primary-140 w-4 h-4 transition duration-100 ease-in-out focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50',
        ellipsis: '',
      },
      variants: {
        rounded: {
          wrapper: 'bg-white mx-auto text-center flex space-x-2',
          element: 'w-4 h-4 rounded-full',
          activeElement: 'w-4 h-4 rounded-full',
          disabledElement: 'w-4 h-4 rounded-full',
          ellipsisElement: 'w-4 h-4 rounded-full hidden md:inline',
          activeButton: 'bg-primary-140 border border-primary-140 bg-primary-140 w-full h-full rounded-full text-primary-140 hover:bg-primary-140-600 hover:text-text-primary-600 transition duration-100 ease-in-out focus:ring-2 focus:outline-none',
          disabledButton: 'border border-gray-200  opacity-25 w-full h-full rounded-full cursor-not-allowed transition duration-100 ease-in-out',
          button: 'text-white border-2 border-primary-140 hover:bg-primary-140 hover:border-primary-140 hover:text-primary-140 rounded-full w-full h-full transition duration-100 ease-in-out focus:outline-none',
          ellipsis: '',
        },
        courseTest: {
          wrapper: 'bg-transparent mx-auto text-center flex space-x-2',
          element: 'w-4 h-4 rounded-full text-opacity-0',
          activeElement: 'w-4 h-4 rounded-full border border-primary-140',
          disabledElement: 'w-4 h-4 rounded-full',
          ellipsisElement: 'w-4 h-4 rounded-full hidden md:inline',
          activeButton: 'bg-primary-140 border border-primary-140 bg-primary-140 w-full h-full rounded-full text-primary-140 hover:bg-primary-140-600 hover:text-text-primary-600 transition duration-100 ease-in-out focus:ring-2 focus:outline-none',
          disabledButton: 'border border-gray-200  opacity-25 w-full h-full rounded-full cursor-not-allowed transition duration-100 ease-in-out',
          button: 'text-opacity-0 text-purple border-2 border-primary-140 hover:bg-primary-140 hover:border-primary-140 hover:text-primary-140 rounded-full w-full h-full transition duration-100 ease-in-out focus:outline-none',
          ellipsis: '',
        },
        largeBlue: {
          wrapper: 'bg-white mx-auto text-center flex space-x-2',
          element: 'w-10 h-10',
          activeElement: 'w-10 h-10',
          disabledElement: 'w-10 h-10',
          ellipsisElement: 'w-10 h-10 hidden md:inline',
          activeButton: 'bg-primary-140 border rounded border-primary-140 bg-primary-140 w-full h-full text-white hover:bg-primary-140-600 hover:text-text-primary-600 transition duration-100 ease-in-out focus:ring-2 focus:outline-none',
          disabledButton: 'border border-gray-200 rounded  opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out',
          button: 'text-text-primary border-2 rounded border-primary-140 hover:bg-primary-140 hover:border-primary-140 hover:text-white w-full h-full transition duration-100 ease-in-out focus:outline-none',
          ellipsis: '',
        },
      },
    },
  },
  't-dropdown': {
    component: TDropdown,
    props: {
      fixedClasses: {
        button: 'text-sm flex items-center font-bold block px-4 py-2 transition duration-100 ease-in-out focus:border-primary-140 focus:ring-2 focus:ring-primary-140 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
        wrapper: 'inline-flex flex-col',
        dropdownWrapper: 'relative z-20',
        dropdown: 'w-50 rounded shadow mt-1 text-sm',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95',
      },
      classes: {
        button: 'bg-primary-140',
        dropdown: 'bg-white w-max origin-top-left absolute left-0',
      },
      variants: {
        course: {
          button: 'bg-transparent',
          dropdown: 'bg-white text-black w-max origin-top-left absolute left-0',
          label: 'text-black',
        },
        nav: {
          button: 'bg-transparent outline-none',
          dropdown: 'bg-primary-140 text-white w-max origin-top-left absolute left-0',
        },
        navRight: {
          button: 'bg-transparent outline-none',
          dropdown: 'bg-primary-140 text-white w-max origin-top-right absolute right-0',
        },
        filter: {
          button: 'bg-transparent',
          dropdown: 'text-text-primary bg-gray-100 overflow-auto max-h-56 max-w-96 min-w-25 pt-2 origin-top-left absolute left-0 ',
          label: 'text-black',
        },
        video: {
          button: 'bg-transparent outline-none',
          dropdown: 'bg-primary-140 text-white w-max origin-top-right absolute right-0',
        },
      },
    },
  },
};

Vue.use(VueTailwind, settings);
