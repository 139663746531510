import { render, staticRenderFns } from "./symposium.vue?vue&type=template&id=59c3fad8&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavFooter: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/nav/Footer.vue').default})
