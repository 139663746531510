import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1dae9825 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _b7e4c2fc = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _a0d488f6 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _911726f6 = () => interopDefault(import('../pages/account/admin/index.vue' /* webpackChunkName: "pages/account/admin/index" */))
const _469af3ec = () => interopDefault(import('../pages/account/course-enrollment/index.vue' /* webpackChunkName: "pages/account/course-enrollment/index" */))
const _128cb4b8 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _1199232a = () => interopDefault(import('../pages/account/preferences.vue' /* webpackChunkName: "pages/account/preferences" */))
const _1a8cfe1c = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _8e0b908c = () => interopDefault(import('../pages/account/subscription.vue' /* webpackChunkName: "pages/account/subscription" */))
const _11074f0a = () => interopDefault(import('../pages/account/admin/compliance/index.vue' /* webpackChunkName: "pages/account/admin/compliance/index" */))
const _67e73716 = () => interopDefault(import('../pages/account/ce-tracker/courses/index.vue' /* webpackChunkName: "pages/account/ce-tracker/courses/index" */))
const _700f030a = () => interopDefault(import('../pages/account/ce-tracker/licenses/index.vue' /* webpackChunkName: "pages/account/ce-tracker/licenses/index" */))
const _71a39848 = () => interopDefault(import('../pages/account/course-enrollment/status.vue' /* webpackChunkName: "pages/account/course-enrollment/status" */))
const _f4dea516 = () => interopDefault(import('../pages/account/instructor/course-details.vue' /* webpackChunkName: "pages/account/instructor/course-details" */))
const _5f902614 = () => interopDefault(import('../pages/account/ce-tracker/courses/add-course/index.vue' /* webpackChunkName: "pages/account/ce-tracker/courses/add-course/index" */))
const _35a91ee0 = () => interopDefault(import('../pages/account/ce-tracker/licenses/add-license/index.vue' /* webpackChunkName: "pages/account/ce-tracker/licenses/add-license/index" */))
const _15798d8d = () => interopDefault(import('../pages/account/admin/compliance/user/_user.vue' /* webpackChunkName: "pages/account/admin/compliance/user/_user" */))
const _b75256ce = () => interopDefault(import('../pages/account/ce-tracker/courses/add-course/_courseId.vue' /* webpackChunkName: "pages/account/ce-tracker/courses/add-course/_courseId" */))
const _3ea4a16f = () => interopDefault(import('../pages/account/ce-tracker/licenses/add-license/_licenseId.vue' /* webpackChunkName: "pages/account/ce-tracker/licenses/add-license/_licenseId" */))
const _f1490f4a = () => interopDefault(import('../pages/account/instructor/session-siso/_instructorId/_sessionId.vue' /* webpackChunkName: "pages/account/instructor/session-siso/_instructorId/_sessionId" */))
const _7c701a1a = () => interopDefault(import('../pages/account/certification-series/_certification.vue' /* webpackChunkName: "pages/account/certification-series/_certification" */))
const _56e6d361 = () => interopDefault(import('../pages/account/course-enrollment/_requestId.vue' /* webpackChunkName: "pages/account/course-enrollment/_requestId" */))
const _1a7bd84b = () => interopDefault(import('../pages/account/order/_orderId/index.vue' /* webpackChunkName: "pages/account/order/_orderId/index" */))
const _f4dae4c8 = () => interopDefault(import('../pages/account/order/_orderId/attest.vue' /* webpackChunkName: "pages/account/order/_orderId/attest" */))
const _1faa59d0 = () => interopDefault(import('../pages/account/order/_orderId/certificate.vue' /* webpackChunkName: "pages/account/order/_orderId/certificate" */))
const _344fe95f = () => interopDefault(import('../pages/account/order/_orderId/report/asha.vue' /* webpackChunkName: "pages/account/order/_orderId/report/asha" */))
const _56ea916f = () => interopDefault(import('../pages/account/order/_orderId/report/cebroker.vue' /* webpackChunkName: "pages/account/order/_orderId/report/cebroker" */))
const _498fbccf = () => interopDefault(import('../pages/account/_type/index.vue' /* webpackChunkName: "pages/account/_type/index" */))
const _57018b4a = () => interopDefault(import('../pages/ce-acceleration-virtual-conference.vue' /* webpackChunkName: "pages/ce-acceleration-virtual-conference" */))
const _40b5ce28 = () => interopDefault(import('../pages/certification-series/index.vue' /* webpackChunkName: "pages/certification-series/index" */))
const _be7144c8 = () => interopDefault(import('../pages/coming-soon.vue' /* webpackChunkName: "pages/coming-soon" */))
const _6c857a8d = () => interopDefault(import('../pages/courses.vue' /* webpackChunkName: "pages/courses" */))
const _239d2350 = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _2069d4ca = () => interopDefault(import('../pages/courses/email-signup-thank-you.vue' /* webpackChunkName: "pages/courses/email-signup-thank-you" */))
const _227ee2c6 = () => interopDefault(import('../pages/courses/free-course/index.vue' /* webpackChunkName: "pages/courses/free-course/index" */))
const _14cc0878 = () => interopDefault(import('../pages/courses/free-course-stu/index.vue' /* webpackChunkName: "pages/courses/free-course-stu/index" */))
const _07718e9e = () => interopDefault(import('../pages/courses/new.vue' /* webpackChunkName: "pages/courses/new" */))
const _405e7bfe = () => interopDefault(import('../pages/courses/category/_category.vue' /* webpackChunkName: "pages/courses/category/_category" */))
const _51182f20 = () => interopDefault(import('../pages/courses/format/_format.vue' /* webpackChunkName: "pages/courses/format/_format" */))
const _792b707a = () => interopDefault(import('../pages/courses/profession/_profession.vue' /* webpackChunkName: "pages/courses/profession/_profession" */))
const _f87c203c = () => interopDefault(import('../pages/courses/setting/_setting.vue' /* webpackChunkName: "pages/courses/setting/_setting" */))
const _54c849c2 = () => interopDefault(import('../pages/courses/state/_state.vue' /* webpackChunkName: "pages/courses/state/_state" */))
const _fa52eece = () => interopDefault(import('../pages/courses/subscription/_type.vue' /* webpackChunkName: "pages/courses/subscription/_type" */))
const _c4a31afc = () => interopDefault(import('../pages/courses/_promo.vue' /* webpackChunkName: "pages/courses/_promo" */))
const _2eb7e99e = () => interopDefault(import('../pages/courses/promo/_discount.vue' /* webpackChunkName: "pages/courses/promo/_discount" */))
const _7a378738 = () => interopDefault(import('../pages/email-signup.vue' /* webpackChunkName: "pages/email-signup" */))
const _83a327c6 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _6f2e279e = () => interopDefault(import('../pages/formats.vue' /* webpackChunkName: "pages/formats" */))
const _5f697e1e = () => interopDefault(import('../pages/free-course.vue' /* webpackChunkName: "pages/free-course" */))
const _654741d6 = () => interopDefault(import('../pages/group-sales/index.vue' /* webpackChunkName: "pages/group-sales/index" */))
const _04a9dbaa = () => interopDefault(import('../pages/live/index.vue' /* webpackChunkName: "pages/live/index" */))
const _534a10c2 = () => interopDefault(import('../pages/live-ce-course-states.vue' /* webpackChunkName: "pages/live-ce-course-states" */))
const _3c7b3fc2 = () => interopDefault(import('../pages/live-webinars.vue' /* webpackChunkName: "pages/live-webinars" */))
const _24974101 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _0a74cb04 = () => interopDefault(import('../pages/other-professionals.vue' /* webpackChunkName: "pages/other-professionals" */))
const _6cf73982 = () => interopDefault(import('../pages/physical-therapist.vue' /* webpackChunkName: "pages/physical-therapist" */))
const _656d8fef = () => interopDefault(import('../pages/physiotec.vue' /* webpackChunkName: "pages/physiotec" */))
const _7f4f9f92 = () => interopDefault(import('../pages/profession.vue' /* webpackChunkName: "pages/profession" */))
const _c63f4bd0 = () => interopDefault(import('../pages/rejoin.vue' /* webpackChunkName: "pages/rejoin" */))
const _7af13d20 = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _9b7b06ee = () => interopDefault(import('../pages/stylesheet.vue' /* webpackChunkName: "pages/stylesheet" */))
const _5d9a679b = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _0214f324 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _780a9ddf = () => interopDefault(import('../pages/topics.vue' /* webpackChunkName: "pages/topics" */))
const _027b1ef1 = () => interopDefault(import('../pages/upgrade.vue' /* webpackChunkName: "pages/upgrade" */))
const _c65f0258 = () => interopDefault(import('../pages/about/privacy-policy.vue' /* webpackChunkName: "pages/about/privacy-policy" */))
const _14ea909b = () => interopDefault(import('../pages/group-sales/ce-solutions.vue' /* webpackChunkName: "pages/group-sales/ce-solutions" */))
const _634f96dc = () => interopDefault(import('../pages/group-sales/compliance-leadership-training.vue' /* webpackChunkName: "pages/group-sales/compliance-leadership-training" */))
const _e99b4b60 = () => interopDefault(import('../pages/group-sales/enterprise/index.vue' /* webpackChunkName: "pages/group-sales/enterprise/index" */))
const _0394e2d2 = () => interopDefault(import('../pages/group-sales/hep.vue' /* webpackChunkName: "pages/group-sales/hep" */))
const _6fe1f50e = () => interopDefault(import('../pages/group-sales/live-webinars.vue' /* webpackChunkName: "pages/group-sales/live-webinars" */))
const _6f8ce303 = () => interopDefault(import('../pages/group-sales/schools.vue' /* webpackChunkName: "pages/group-sales/schools" */))
const _f8686c12 = () => interopDefault(import('../pages/group-sales/small-teams/index.vue' /* webpackChunkName: "pages/group-sales/small-teams/index" */))
const _d5604914 = () => interopDefault(import('../pages/instructor/directory.vue' /* webpackChunkName: "pages/instructor/directory" */))
const _a2b0cc42 = () => interopDefault(import('../pages/live/terms.vue' /* webpackChunkName: "pages/live/terms" */))
const _380167f4 = () => interopDefault(import('../pages/login/forgot-password.vue' /* webpackChunkName: "pages/login/forgot-password" */))
const _5249a0af = () => interopDefault(import('../pages/login/forgot-username.vue' /* webpackChunkName: "pages/login/forgot-username" */))
const _a7413a8c = () => interopDefault(import('../pages/login/reset-password.vue' /* webpackChunkName: "pages/login/reset-password" */))
const _77a5bb17 = () => interopDefault(import('../pages/subscription/all-access.vue' /* webpackChunkName: "pages/subscription/all-access" */))
const _49fbfc2e = () => interopDefault(import('../pages/subscription/expired.vue' /* webpackChunkName: "pages/subscription/expired" */))
const _0631619c = () => interopDefault(import('../pages/subscription/hep.vue' /* webpackChunkName: "pages/subscription/hep" */))
const _24222de7 = () => interopDefault(import('../pages/subscription/online-access.vue' /* webpackChunkName: "pages/subscription/online-access" */))
const _a7428762 = () => interopDefault(import('../pages/subscription/pricing.vue' /* webpackChunkName: "pages/subscription/pricing" */))
const _3a3265b5 = () => interopDefault(import('../pages/subscription/subscription-v2.vue' /* webpackChunkName: "pages/subscription/subscription-v2" */))
const _df59bc14 = () => interopDefault(import('../pages/subscription/tc.vue' /* webpackChunkName: "pages/subscription/tc" */))
const _3ce4c8b6 = () => interopDefault(import('../pages/subscription/upgrade.vue' /* webpackChunkName: "pages/subscription/upgrade" */))
const _c99e2fc6 = () => interopDefault(import('../pages/subscription/upgrade-allaccess.vue' /* webpackChunkName: "pages/subscription/upgrade-allaccess" */))
const _5418a30a = () => interopDefault(import('../pages/certification-series/old/adolescent-athlete.vue' /* webpackChunkName: "pages/certification-series/old/adolescent-athlete" */))
const _c655372a = () => interopDefault(import('../pages/certification-series/old/cancer-rehab.vue' /* webpackChunkName: "pages/certification-series/old/cancer-rehab" */))
const _4ca8c8db = () => interopDefault(import('../pages/certification-series/old/cardiovascular-and-pulmonary.vue' /* webpackChunkName: "pages/certification-series/old/cardiovascular-and-pulmonary" */))
const _1453b5b0 = () => interopDefault(import('../pages/certification-series/old/fall-prevention.vue' /* webpackChunkName: "pages/certification-series/old/fall-prevention" */))
const _f522941c = () => interopDefault(import('../pages/certification-series/old/geriatric.vue' /* webpackChunkName: "pages/certification-series/old/geriatric" */))
const _2292de8d = () => interopDefault(import('../pages/certification-series/old/hand-therapy.vue' /* webpackChunkName: "pages/certification-series/old/hand-therapy" */))
const _2405fef8 = () => interopDefault(import('../pages/certification-series/old/home-health.vue' /* webpackChunkName: "pages/certification-series/old/home-health" */))
const _a0d26e78 = () => interopDefault(import('../pages/certification-series/old/lymphedema.vue' /* webpackChunkName: "pages/certification-series/old/lymphedema" */))
const _21acfb57 = () => interopDefault(import('../pages/certification-series/old/sports-performance-and-rehab.vue' /* webpackChunkName: "pages/certification-series/old/sports-performance-and-rehab" */))
const _9da8468c = () => interopDefault(import('../pages/certification-series/old/stroke.vue' /* webpackChunkName: "pages/certification-series/old/stroke" */))
const _441c101a = () => interopDefault(import('../pages/certification-series/old/vestibular.vue' /* webpackChunkName: "pages/certification-series/old/vestibular" */))
const _7ae96c62 = () => interopDefault(import('../pages/certification-series/old/womens-health.vue' /* webpackChunkName: "pages/certification-series/old/womens-health" */))
const _dd8206a8 = () => interopDefault(import('../pages/group-sales/client/edfund.vue' /* webpackChunkName: "pages/group-sales/client/edfund" */))
const _8921185c = () => interopDefault(import('../pages/group-sales/client/ivyrehab.vue' /* webpackChunkName: "pages/group-sales/client/ivyrehab" */))
const _4440ae7c = () => interopDefault(import('../pages/group-sales/client/physiotec.vue' /* webpackChunkName: "pages/group-sales/client/physiotec" */))
const _2d3b895d = () => interopDefault(import('../pages/group-sales/client/synchrony.vue' /* webpackChunkName: "pages/group-sales/client/synchrony" */))
const _2b8e5d01 = () => interopDefault(import('../pages/group-sales/client/wvbot/_sessionId.vue' /* webpackChunkName: "pages/group-sales/client/wvbot/_sessionId" */))
const _76c92186 = () => interopDefault(import('../pages/group-sales/old-rewards/_slug.vue' /* webpackChunkName: "pages/group-sales/old-rewards/_slug" */))
const _580f3363 = () => interopDefault(import('../pages/group-sales/rewards/_slug.vue' /* webpackChunkName: "pages/group-sales/rewards/_slug" */))
const _4c35a19c = () => interopDefault(import('../pages/group-sales/setting/_setting.vue' /* webpackChunkName: "pages/group-sales/setting/_setting" */))
const _76b423e5 = () => interopDefault(import('../pages/live/in-person-waiver/_sessionId.vue' /* webpackChunkName: "pages/live/in-person-waiver/_sessionId" */))
const _dec6b842 = () => interopDefault(import('../pages/live/live-registration/_sessionId.vue' /* webpackChunkName: "pages/live/live-registration/_sessionId" */))
const _0ece7976 = () => interopDefault(import('../pages/subscription/core100/_slug.vue' /* webpackChunkName: "pages/subscription/core100/_slug" */))
const _39447a30 = () => interopDefault(import('../pages/certification-series/_certSeries.vue' /* webpackChunkName: "pages/certification-series/_certSeries" */))
const _35422b85 = () => interopDefault(import('../pages/instructor/_instructor.vue' /* webpackChunkName: "pages/instructor/_instructor" */))
const _6555f5c2 = () => interopDefault(import('../pages/online-course/_orderId/index.vue' /* webpackChunkName: "pages/online-course/_orderId/index" */))
const _0af954e4 = () => interopDefault(import('../pages/profession-v2/_profession.vue' /* webpackChunkName: "pages/profession-v2/_profession" */))
const _0a665a7a = () => interopDefault(import('../pages/s/_slug.vue' /* webpackChunkName: "pages/s/_slug" */))
const _556f2c46 = () => interopDefault(import('../pages/signature-series/_seriesName/nashville-july-2025.vue' /* webpackChunkName: "pages/signature-series/_seriesName/nashville-july-2025" */))
const _4dabcdd7 = () => interopDefault(import('../pages/api/_yetiCode/impersonate/_personId.vue' /* webpackChunkName: "pages/api/_yetiCode/impersonate/_personId" */))
const _14f8a121 = () => interopDefault(import('../pages/b/_brochureId/_slug.vue' /* webpackChunkName: "pages/b/_brochureId/_slug" */))
const _2575d5b7 = () => interopDefault(import('../pages/course/_courseId/_slug.vue' /* webpackChunkName: "pages/course/_courseId/_slug" */))
const _55550d47 = () => interopDefault(import('../pages/online-course/_orderId/_previewId/index.vue' /* webpackChunkName: "pages/online-course/_orderId/_previewId/index" */))
const _627c2f06 = () => interopDefault(import('../pages/siso/_instructorId/_method.vue' /* webpackChunkName: "pages/siso/_instructorId/_method" */))
const _6b4ac51c = () => interopDefault(import('../pages/state-and-profession/_profession/_state.vue' /* webpackChunkName: "pages/state-and-profession/_profession/_state" */))
const _52a471b6 = () => interopDefault(import('../pages/siso/_instructorId/_orderId/_method.vue' /* webpackChunkName: "pages/siso/_instructorId/_orderId/_method" */))
const _7a684de7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _29964217 = () => interopDefault(import('../pages/_cart/index.vue' /* webpackChunkName: "pages/_cart/index" */))
const _a0a92000 = () => interopDefault(import('../pages/_cart/confirmation.vue' /* webpackChunkName: "pages/_cart/confirmation" */))
const _66addec4 = () => interopDefault(import('../pages/_cart/addAttendees/_slug.vue' /* webpackChunkName: "pages/_cart/addAttendees/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _1dae9825,
    name: "about"
  }, {
    path: "/account",
    component: _b7e4c2fc,
    children: [{
      path: "",
      component: _a0d488f6,
      name: "account"
    }, {
      path: "admin",
      component: _911726f6,
      name: "account-admin"
    }, {
      path: "course-enrollment",
      component: _469af3ec,
      name: "account-course-enrollment"
    }, {
      path: "password",
      component: _128cb4b8,
      name: "account-password"
    }, {
      path: "preferences",
      component: _1199232a,
      name: "account-preferences"
    }, {
      path: "profile",
      component: _1a8cfe1c,
      name: "account-profile"
    }, {
      path: "subscription",
      component: _8e0b908c,
      name: "account-subscription"
    }, {
      path: "admin/compliance",
      component: _11074f0a,
      name: "account-admin-compliance"
    }, {
      path: "ce-tracker/courses",
      component: _67e73716,
      name: "account-ce-tracker-courses"
    }, {
      path: "ce-tracker/licenses",
      component: _700f030a,
      name: "account-ce-tracker-licenses"
    }, {
      path: "course-enrollment/status",
      component: _71a39848,
      name: "account-course-enrollment-status"
    }, {
      path: "instructor/course-details",
      component: _f4dea516,
      name: "account-instructor-course-details"
    }, {
      path: "ce-tracker/courses/add-course",
      component: _5f902614,
      name: "account-ce-tracker-courses-add-course"
    }, {
      path: "ce-tracker/licenses/add-license",
      component: _35a91ee0,
      name: "account-ce-tracker-licenses-add-license"
    }, {
      path: "admin/compliance/user/:user?",
      component: _15798d8d,
      name: "account-admin-compliance-user-user"
    }, {
      path: "ce-tracker/courses/add-course/:courseId",
      component: _b75256ce,
      name: "account-ce-tracker-courses-add-course-courseId"
    }, {
      path: "ce-tracker/licenses/add-license/:licenseId",
      component: _3ea4a16f,
      name: "account-ce-tracker-licenses-add-license-licenseId"
    }, {
      path: "instructor/session-siso/:instructorId?/:sessionId?",
      component: _f1490f4a,
      name: "account-instructor-session-siso-instructorId-sessionId"
    }, {
      path: "certification-series/:certification?",
      component: _7c701a1a,
      name: "account-certification-series-certification"
    }, {
      path: "course-enrollment/:requestId?",
      component: _56e6d361,
      name: "account-course-enrollment-requestId"
    }, {
      path: "order/:orderId",
      component: _1a7bd84b,
      name: "account-order-orderId"
    }, {
      path: "order/:orderId?/attest",
      component: _f4dae4c8,
      name: "account-order-orderId-attest"
    }, {
      path: "order/:orderId?/certificate",
      component: _1faa59d0,
      name: "account-order-orderId-certificate"
    }, {
      path: "order/:orderId?/report/asha",
      component: _344fe95f,
      name: "account-order-orderId-report-asha"
    }, {
      path: "order/:orderId?/report/cebroker",
      component: _56ea916f,
      name: "account-order-orderId-report-cebroker"
    }, {
      path: ":type",
      component: _498fbccf,
      name: "account-type"
    }]
  }, {
    path: "/ce-acceleration-virtual-conference",
    component: _57018b4a,
    name: "ce-acceleration-virtual-conference"
  }, {
    path: "/certification-series",
    component: _40b5ce28,
    name: "certification-series"
  }, {
    path: "/coming-soon",
    component: _be7144c8,
    name: "coming-soon"
  }, {
    path: "/courses",
    component: _6c857a8d,
    children: [{
      path: "",
      component: _239d2350,
      name: "courses"
    }, {
      path: "email-signup-thank-you",
      component: _2069d4ca,
      name: "courses-email-signup-thank-you"
    }, {
      path: "free-course",
      component: _227ee2c6,
      name: "courses-free-course"
    }, {
      path: "free-course-stu",
      component: _14cc0878,
      name: "courses-free-course-stu"
    }, {
      path: "new",
      component: _07718e9e,
      name: "courses-new"
    }, {
      path: "category/:category?",
      component: _405e7bfe,
      name: "courses-category-category"
    }, {
      path: "format/:format?",
      component: _51182f20,
      name: "courses-format-format"
    }, {
      path: "profession/:profession?",
      component: _792b707a,
      name: "courses-profession-profession"
    }, {
      path: "setting/:setting?",
      component: _f87c203c,
      name: "courses-setting-setting"
    }, {
      path: "state/:state?",
      component: _54c849c2,
      name: "courses-state-state"
    }, {
      path: "subscription/:type?",
      component: _fa52eece,
      name: "courses-subscription-type"
    }, {
      path: ":promo",
      component: _c4a31afc,
      name: "courses-promo",
      children: [{
        path: ":discount?",
        component: _2eb7e99e,
        name: "courses-promo-discount"
      }]
    }]
  }, {
    path: "/email-signup",
    component: _7a378738,
    name: "email-signup"
  }, {
    path: "/error",
    component: _83a327c6,
    name: "error"
  }, {
    path: "/formats",
    component: _6f2e279e,
    name: "formats"
  }, {
    path: "/free-course",
    component: _5f697e1e,
    name: "free-course"
  }, {
    path: "/group-sales",
    component: _654741d6,
    name: "group-sales"
  }, {
    path: "/live",
    component: _04a9dbaa,
    name: "live"
  }, {
    path: "/live-ce-course-states",
    component: _534a10c2,
    name: "live-ce-course-states"
  }, {
    path: "/live-webinars",
    component: _3c7b3fc2,
    name: "live-webinars"
  }, {
    path: "/login",
    component: _24974101,
    name: "login"
  }, {
    path: "/other-professionals",
    component: _0a74cb04,
    name: "other-professionals"
  }, {
    path: "/physical-therapist",
    component: _6cf73982,
    name: "physical-therapist"
  }, {
    path: "/physiotec",
    component: _656d8fef,
    name: "physiotec"
  }, {
    path: "/profession",
    component: _7f4f9f92,
    name: "profession"
  }, {
    path: "/rejoin",
    component: _c63f4bd0,
    name: "rejoin"
  }, {
    path: "/reviews",
    component: _7af13d20,
    name: "reviews"
  }, {
    path: "/stylesheet",
    component: _9b7b06ee,
    name: "stylesheet"
  }, {
    path: "/subscription",
    component: _5d9a679b,
    name: "subscription"
  }, {
    path: "/support",
    component: _0214f324,
    name: "support"
  }, {
    path: "/topics",
    component: _780a9ddf,
    name: "topics"
  }, {
    path: "/upgrade",
    component: _027b1ef1,
    name: "upgrade"
  }, {
    path: "/about/privacy-policy",
    component: _c65f0258,
    name: "about-privacy-policy"
  }, {
    path: "/group-sales/ce-solutions",
    component: _14ea909b,
    name: "group-sales-ce-solutions"
  }, {
    path: "/group-sales/compliance-leadership-training",
    component: _634f96dc,
    name: "group-sales-compliance-leadership-training"
  }, {
    path: "/group-sales/enterprise",
    component: _e99b4b60,
    name: "group-sales-enterprise"
  }, {
    path: "/group-sales/hep",
    component: _0394e2d2,
    name: "group-sales-hep"
  }, {
    path: "/group-sales/live-webinars",
    component: _6fe1f50e,
    name: "group-sales-live-webinars"
  }, {
    path: "/group-sales/schools",
    component: _6f8ce303,
    name: "group-sales-schools"
  }, {
    path: "/group-sales/small-teams",
    component: _f8686c12,
    name: "group-sales-small-teams"
  }, {
    path: "/instructor/directory",
    component: _d5604914,
    name: "instructor-directory"
  }, {
    path: "/live/terms",
    component: _a2b0cc42,
    name: "live-terms"
  }, {
    path: "/login/forgot-password",
    component: _380167f4,
    name: "login-forgot-password"
  }, {
    path: "/login/forgot-username",
    component: _5249a0af,
    name: "login-forgot-username"
  }, {
    path: "/login/reset-password",
    component: _a7413a8c,
    name: "login-reset-password"
  }, {
    path: "/subscription/all-access",
    component: _77a5bb17,
    name: "subscription-all-access"
  }, {
    path: "/subscription/expired",
    component: _49fbfc2e,
    name: "subscription-expired"
  }, {
    path: "/subscription/hep",
    component: _0631619c,
    name: "subscription-hep"
  }, {
    path: "/subscription/online-access",
    component: _24222de7,
    name: "subscription-online-access"
  }, {
    path: "/subscription/pricing",
    component: _a7428762,
    name: "subscription-pricing"
  }, {
    path: "/subscription/subscription-v2",
    component: _3a3265b5,
    name: "subscription-subscription-v2"
  }, {
    path: "/subscription/tc",
    component: _df59bc14,
    name: "subscription-tc"
  }, {
    path: "/subscription/upgrade",
    component: _3ce4c8b6,
    name: "subscription-upgrade"
  }, {
    path: "/subscription/upgrade-allaccess",
    component: _c99e2fc6,
    name: "subscription-upgrade-allaccess"
  }, {
    path: "/certification-series/old/adolescent-athlete",
    component: _5418a30a,
    name: "certification-series-old-adolescent-athlete"
  }, {
    path: "/certification-series/old/cancer-rehab",
    component: _c655372a,
    name: "certification-series-old-cancer-rehab"
  }, {
    path: "/certification-series/old/cardiovascular-and-pulmonary",
    component: _4ca8c8db,
    name: "certification-series-old-cardiovascular-and-pulmonary"
  }, {
    path: "/certification-series/old/fall-prevention",
    component: _1453b5b0,
    name: "certification-series-old-fall-prevention"
  }, {
    path: "/certification-series/old/geriatric",
    component: _f522941c,
    name: "certification-series-old-geriatric"
  }, {
    path: "/certification-series/old/hand-therapy",
    component: _2292de8d,
    name: "certification-series-old-hand-therapy"
  }, {
    path: "/certification-series/old/home-health",
    component: _2405fef8,
    name: "certification-series-old-home-health"
  }, {
    path: "/certification-series/old/lymphedema",
    component: _a0d26e78,
    name: "certification-series-old-lymphedema"
  }, {
    path: "/certification-series/old/sports-performance-and-rehab",
    component: _21acfb57,
    name: "certification-series-old-sports-performance-and-rehab"
  }, {
    path: "/certification-series/old/stroke",
    component: _9da8468c,
    name: "certification-series-old-stroke"
  }, {
    path: "/certification-series/old/vestibular",
    component: _441c101a,
    name: "certification-series-old-vestibular"
  }, {
    path: "/certification-series/old/womens-health",
    component: _7ae96c62,
    name: "certification-series-old-womens-health"
  }, {
    path: "/group-sales/client/edfund",
    component: _dd8206a8,
    name: "group-sales-client-edfund"
  }, {
    path: "/group-sales/client/ivyrehab",
    component: _8921185c,
    name: "group-sales-client-ivyrehab"
  }, {
    path: "/group-sales/client/physiotec",
    component: _4440ae7c,
    name: "group-sales-client-physiotec"
  }, {
    path: "/group-sales/client/synchrony",
    component: _2d3b895d,
    name: "group-sales-client-synchrony"
  }, {
    path: "/group-sales/client/wvbot/:sessionId?",
    component: _2b8e5d01,
    name: "group-sales-client-wvbot-sessionId"
  }, {
    path: "/group-sales/old-rewards/:slug",
    component: _76c92186,
    name: "group-sales-old-rewards-slug"
  }, {
    path: "/group-sales/rewards/:slug",
    component: _580f3363,
    name: "group-sales-rewards-slug"
  }, {
    path: "/group-sales/setting/:setting",
    component: _4c35a19c,
    name: "group-sales-setting-setting"
  }, {
    path: "/live/in-person-waiver/:sessionId?",
    component: _76b423e5,
    name: "live-in-person-waiver-sessionId"
  }, {
    path: "/live/live-registration/:sessionId?",
    component: _dec6b842,
    name: "live-live-registration-sessionId"
  }, {
    path: "/subscription/core100/:slug?",
    component: _0ece7976,
    name: "subscription-core100-slug"
  }, {
    path: "/certification-series/:certSeries?",
    component: _39447a30,
    name: "certification-series-certSeries"
  }, {
    path: "/instructor/:instructor?",
    component: _35422b85,
    name: "instructor-instructor"
  }, {
    path: "/online-course/:orderId",
    component: _6555f5c2,
    name: "online-course-orderId"
  }, {
    path: "/profession-v2/:profession?",
    component: _0af954e4,
    name: "profession-v2-profession"
  }, {
    path: "/s/:slug?",
    component: _0a665a7a,
    name: "s-slug"
  }, {
    path: "/signature-series/:seriesName?/nashville-july-2025",
    component: _556f2c46,
    name: "signature-series-seriesName-nashville-july-2025"
  }, {
    path: "/api/:yetiCode?/impersonate/:personId?",
    component: _4dabcdd7,
    name: "api-yetiCode-impersonate-personId"
  }, {
    path: "/b/:brochureId?/:slug?",
    component: _14f8a121,
    name: "b-brochureId-slug"
  }, {
    path: "/course/:courseId?/:slug?",
    component: _2575d5b7,
    name: "course-courseId-slug"
  }, {
    path: "/online-course/:orderId?/:previewId",
    component: _55550d47,
    name: "online-course-orderId-previewId"
  }, {
    path: "/siso/:instructorId?/:method?",
    component: _627c2f06,
    name: "siso-instructorId-method"
  }, {
    path: "/state-and-profession/:profession?/:state?",
    component: _6b4ac51c,
    name: "state-and-profession-profession-state"
  }, {
    path: "/siso/:instructorId?/:orderId?/:method?",
    component: _52a471b6,
    name: "siso-instructorId-orderId-method"
  }, {
    path: "/",
    component: _7a684de7,
    name: "index"
  }, {
    path: "/:cart",
    component: _29964217,
    name: "cart"
  }, {
    path: "/:cart/confirmation",
    component: _a0a92000,
    name: "cart-confirmation"
  }, {
    path: "/:cart/addAttendees/:slug?",
    component: _66addec4,
    name: "cart-addAttendees-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
